/*
 * Bootstrap break points
 * Extra small - <576px
 * Small - ≥576px
 * Medium - ≥768px
 * Large - ≥992px
 * Extra large - ≥1200px
*/


@media(max-width: 939px){
  .spacer{
    margin-top: -15px !important;
  }
}

@media(min-width: 940px){
  .w-f-size-40{
    font-size: 55px !important;
  }
}
