@media(max-width: 939px){

	.content{
		width:100%;
		padding: 10px;
	}

	article{
		width: 100%;
		margin: 0;
	}

	#home #content{
		width: 100%;
		padding: 0 10px;
	}

	#home h1{
		font-size: 1.8em;
	}


	#home h2{
		font-size: 30px;
		margin-bottom: 5px;
		line-height: 35px;
	}


	#home h3{
		font-size: 18px;
	}


	#header-desktop{
		display: none !important;
	}

	.header-mobile{
		display: block;

	}

	.logo-mobile img{
		width: 80px;
	}

	#main-banner-wrapper{
		display: none;
	}


	#headline{
		padding-top: 20px;
		margin-bottom: 30px !important;
	}

	#headline h3{
		font-size: 1.4em;
		line-height: 1.2em;
	}

	#headline p{
		text-align: center;
		font-size: 0.9em;
		line-height: 1.3em;
		margin-bottom: 20px;
	}


	/*Packages*/
	.packages-titles{
		font-size: 1.5em;
		margin-bottom: 20px;
	}


	.package-box-wrapper{
		width: 100% !important;
		margin-bottom: 20px !important;
	}

	.package-boxes{
		padding-bottom: 20px;
		min-height: 10px !important;
		/*To force the button align to the bottom of this box*/
		position: relative;
	}


	.package-boxes li{
		text-align: center !important;
	}


	.package-box-5-wrapper{
		margin-left: 0;
	}

	.package-box-5 .cifrao{
		margin-left: 30px;
	}

	.package-box-5 .btn-ask-yours{
		margin: 50px 30% -10px 22%;
	}


	a.btn-ask-yours{
		position: relative;
		margin: 0 auto;
		width: 200px;
		text-align: center;
		margin-top: 60px;
	}


	/*BTN Solicite o Seu*/
	.btn-solicite-o-seu{
		position: relative;
		text-align:center;
		width: 260px;
		margin: 0 auto !important;
		margin-top: 60px !important;
		margin-bottom: 0px !important;
	}

	#para-empresa .btn-solicite-o-seu{
		width: 260px !important;
	}

	/*Features Box*/
	#features-box .left-col{
		float: none;
		width: 100% !important;
		margin:0 !important;
		padding: 20px;
	}

	#features-box .left-col h3{
		font-size: 1.5em !important;
		margin-bottom: 50px;
		padding-top: 20px;
	}

	#features-box .left-col img{
		width: 200px;
	}

	#features-box .right-col{
		float: none;
		margin:0 !important;
		width: 100% !important;
		padding: 10px;
		margin-bottom: 10px !important;
	}


	#features-box #accordion h3{
		padding-left: 50px !important;
	}



	#features-box-footer{
		text-align: center;

	}


	#features-box-footer h5{
		float: none !important;
		margin-right: 0 !important;
		text-align: center;
		overflow: hidden;
		clear: both;
		padding-top: 20px;
	}


	#features-box .ui-accordion .ui-accordion-header .ui-accordion-header-icon{
		display: none;
	}


	/*Package Lightboxes*/
	.modal {
		width: 100% !important;
	}

	.lightbox-tab-top{
		width: 100% !important;
	}

	.package-lightbox-content{
		padding: 10px !important;
		width: 100%;
	}

	.modal .package-price{
		display: none;
	}

	.package-lightbox-form{
		width: 100%;
	}

	.package-lightbox-form input{
		width: 100%;
	}

	.modal div.form_error{
		width: 100% !important;
	}


	/*Seguro*/
	#seguro-banner-reasons-wrapper{
		width: 100% !important;
		padding: 10px;
	}

	#seguro-banner-reasons{
		width: 100% !important;
	}

	#seguro-banner-reasons h3{
		font-size: 1.6em !important;
		margin-bottom: 20px !important;
		padding: 0 !important;
	}

	#seguro-banner-reasons div{
		width: 100% !important;
		float: none !important;
		margin-bottom: 20px !important;
	}

	#seguro-banner-reasons div>p{
		font-size: 1.2em;
		line-height: 1.3em;
	}

	#seguro-banner-reasons p{
		font-size: 1em;
		line-height: 1.4em;
	}

	.seguro-boxes-destaques-wrapper{
		width: 100% !important;
	}

	.seguro-destaque-boxes{
		width: 100% !important;
	}


	/*Sobre Nos*/
	#sobre-nos-valores div{
		margin-bottom: 10px;
		padding: 15px;
		border-bottom: 4px solid #00adee;
		width: 100%;
	}

	#sobre-nos-valores{
		padding: 10px;
	}

	#sobre-nos .funcionarios-box{
		float: left;
		margin-bottom: 50px;
		width: 100%;
	}


	/*Para Empresa*/
	.seguradoras-logos a{
		border: 1px solid #c0d9f0;
		margin: 10px;
	}

	ul.seguradoras-logos{
		margin: 20px auto;
		width: 244px;
	}


	/*Sobre Nós*/
	#sobre-nos-valores h4{
		font-size: 1.3em;
	}


	/*Moto*/
	#moto .package-boxes-container{
		width: 100%;
	}

	#moto #accordion{
		width: 100%;
	}


	/*Contact*/
	#contact-form{
		width: 100%;
	}

	#contato h3{
		font-size: 1.3em;
	}

	#contact-form input{
		width: 100%;
		margin-right: 0;
	}

	#contact-form select{
		width: 100%;
	}

	#contact-form textarea{
		height: 160px;
		width: 100%;
	}


	/*Ouvidoria Form*/
	#ouvidoria-form{
		width: 100%;
	}

	#ouvidoria-form input{
		width: 100%;
		float: none;
	}

	#ouvidoria-form textarea{
		width: 100%;
		height: 170px;
	}



	/*Buttons*/
	.btn-pre-home{
		width: 100%;
		margin-bottom: 5px;
	}

	.btn-pre-home-wrapper{
		width: 100%;

	}


	//Selectors btn PARA VOCÊ - PARA EMPRESA
	button.btn-selector-mobile-fade{
		opacity: 0.35;
	}


	/*Banner para empresa*/
	#banner-gestor-plus{
		display:none;
	}



	/*Calculadora Seguro*/
	.btn-calcular-seguro-seletores-container
	{
		width: 100%;
	}

	.btn-seletor-calcularora-seguro input[type=radio]+label
	{
		width: 100%;
		margin-bottom: 15px;
	}


	#calculadora-seguro fieldset{
		padding: 10px;
		width: 100% !important;
	}

	#calculadora-seguro input[type="text"],
	#calculadora-seguro input[type="email"],
	#calculadora-seguro input[type="number"]{
		width: 100%;
	}

	#calculadora-seguro select{
		width: 100% !important;
	}

	#calculadora-seguro option{
		color: #red !important;
	}


	#calculadora-seguro .pacote-rastreamento-left-col{
		margin-left: 0 !important;
		width: 115px;
	}


	/*Manuais*/
	.manuais-boxes{
		width: 100%;
	}

	.btn-manuais-download{
		margin: 0 auto;
	}

	/*Patio Legal*/
	#patiolegal article{
		padding-top: 30px;
	}


	/*Manual Seguro Auto*/
	#manual-seguro-auto .splashIcon{
		margin-top: 40px;
		margin-bottom: 20px;
	}

	#manual-seguro-auto .manual-seguro-auto-boxes-destaques-wrapper{
		width: 100%;
	}

	#manual-seguro-auto .manual-seguro-destaque-boxes{
		width: 100%;
	}


	/*Dropdown*/
	.dropdown-submenu-mobile{
		display: none;
		font-family:Helvetica, Arial, sans-serif !important;
	}

	.dropdowm-menu-mobile:hover>.dropdown-submenu-mobile{
		display: block;
	}

	/*Footer*/

	#footer-level1-wrapper, #footer-level2-wrapper, #footer-level3-wrapper{
		padding-left: 10px;
		padding-right: 10px;
	}


	#footer-level1{
		width: 100%;
	}
	#footer-social-media-icons{
		display:none;
	}

	.footer-help-button{
		float: none;
		margin: 0 auto;

	}

	#footer-level2-wrapper{
		padding: 20px 10px;
	}

	#footer-level2{
		width: 100%;
		margin: 0 auto;
		overflow: hidden;
		line-height: 21px;
		text-align: center;
	}

	#footer-level2-left-col{
		width: 100%;
		margin-bottom: 20px;
	}

	#footer-level2-right-col{
		width: 100%;
		margin-bottom: 20px;
	}


	#footer-level2 img{
		float: none;
		margin: 0 auto;
		display: block;
		width: 90px;
		overflow: hidden;
	}

	#footer-level3{
		text-align: center;
		width: 100%;
	}


	#footer-level3 .left-col{
		float: none;
		margin-bottom: 20px;
	}

	#footer-level3 a{
		float: none;
	}


	/*Logos*/
	#logos .logos-boxes-blue-cyan-wrapper{
		width: 100%;
		margin-top: 30px;
		padding-bottom: 0 !important;
		margin-bottom: 0 !important;
	}

	#logos .logos-box-color-blue{
		margin: 0 auto !important;
		float: none;
		margin-bottom: 20px !important;
	}

	#logos .logos-box-color-cyan{
		margin: 0 auto !important;
		float: none;
		margin-bottom: 0 !important;
	}



}
/*End Mobile*/
