@function palette($palette, $tone: "base") {
  @return map-get(map-get($palettes, $palette), $tone);
}

$palettes: (
  paragraph: (
    paragraph: #333,
  ),
  grey: (
    grey-base: #8697a8,
    grey-light: #f7f8fa,
    grey-medium: #edeef1,
    grey-dark: #000000,
  ),
  brand: (
    blue: #004b99,
    lightBlue: #00a4e3,
    lightBlue-30: rgba(0, 164, 227, 0.3),
    darkBlue: #003973,
    green: #3c7c04,
    darkGreen: #2f6103,
    lightkGreen: #1b7e3e,
  ),
);

// Texts colors
.brand-blue {
  color: palette(brand, blue) !important;
}
.brand-dark-blue {
  color: palette(brand, darkBlue) !important;
}
.brand-light-blue {
  color: palette(brand, lightBlue) !important;
}

.brand-light-blue-30 {
  color: palette(brand, lightBlue-30) !important;
}

.brand-grey-base {
  color: palette(grey, grey-base) !important;
}
.text-black{
  color: #000 !important;
}

// Backgrounds
.brand-bg-blue {
  background: palette(brand, blue) !important;
}
.brand-bg-light-blue {
  background: palette(brand, lightBlue) !important;
}
.brand-bg-dark-blue {
  background: palette(brand, darkBlue) !important;
}
.brand-bg-green {
  background: palette(brand, green) !important;
}
.brand-bg-green-light {
  background: palette(brand, lightkGreen) !important;
}
.brand-bg-grey-light {
  background: palette(grey, grey-medium) !important;
}

/* NÃO DELETAR OS CÓDIGOS ABAIXO POIS TEM VÁRIAS DEPENDENCIA NO PROJETO
METODO ANTIGO DE APLICAR CORES - LENTAMENTE MIGRAR PARA OS CODIGOS ACIMA*/

//Colors
$green: #3c7c04;
$darkGreen: #2f6103;
$lightkGreen: #1b7e3e;
$blue: #005aa9;
$lightBlue: #00a4e3;
$darkBlue: #00437f;

$paragraphColor: #333;

// Backgrunds
// .brand-bg-blue { background: $blue !important; }
.bg-satcom-light-blue {
  background: $lightBlue !important;
}
.bg-satcom-green {
  background: $green !important;
}
.bg-satcom-light-green {
  background: $lightkGreen !important;
}
