@charset "utf-8";
/*Louvado seja Deus acima de todas as coisas*/

.tooltip{
	position: relative;
	z-index: 20;
}

.tooltip ul{
	display: none;
	background: #005aa9;
	padding: 10px;
	border: 1px solid #01497f;
    bottom: 20px;
    left: 43%;
    width: 160px;
}

.tooltip li{
	border-bottom: 1px solid #01497f;
	padding: 6px 0;
	color: #fff;
}

.tooltip:hover{
	z-index: 21;
}


.tooltip:hover ul{
	display: block;
	position: absolute;

}
