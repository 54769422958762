// Banner
.satcom-bg-jumbotron {
	background-image: url('../img/rastreador-landing-jumbotron-bg.png');
}

//Landing Page - Mobile
#landingpage header, #cadastroSuccess header{ 
	background: #005aa9;
	padding: 10px !important;
}

#landingpage header p, #cadastroSuccess header p{
	color: #fff;
	margin-top: 40px;
	text-align: right;
}


#cadastroSuccess h1{
	margin-top: 40px
}

#cadastroSuccess p{
	margin-bottom: 40px
}


#landingpage section{
	padding-bottom: 20px;
}

#landingpage .img-center{
	margin: 0 auto;
}

#landingpage h1{
	font-size: 1.8em;
}


#landingpage .btns-landing-mobile-app-container{
	margin: 40px auto 15px auto;
	width: 134px;
}

#landingpage .btns-landing-mobile-app-container img:first-child{
	margin-bottom: 15px;
}

#landingpage .meavise{
	margin-bottom: 40px;
	text-decoration: underline;
	color: #616972;
	text-align: center;
}

.landingPageTextAlignCenter{
	text-align: center;
}



// Form
#landingpage label{
	margin-top: 15px;
	display: none;
}

#landingpage input{
	margin-bottom: 10px;
}



//Landing Page - Desktop

//Banner
.landingPageBanner{
	background: #F2F2F2 url('../img/landingPageAppLocalizaVeiculoBanner.jpg') no-repeat top center;
	padding-top: 80px;
	overflow: hidden;
	padding-bottom: 20px;
}


.landingPageBanner h1{
	color: #fff;
	font-size: 58px !important;
	text-align: left;
	margin-bottom: 60px;
	line-height: 1.2em;
}


.landingPageBanner p{
	color: #fff !important;
	font-size: 22px !important;
	line-height: 1.4em !important;
	margin-top: 15px;
	margin-bottom: 15px;
}

.landingPageBanner a{
	color: #fff !important;
	font-size: 14px !important;
	text-decoration: underline !important;
	text-align: left !important;
	margin-top: 10px !important;
}

.landingPageBannerIphone{
	// margin-top: 40px;
	margin-bottom: 60px;
}

.landingPageGoogleBadge{
	margin-bottom: 20px;
}

// Main contente container
.landingPageContentContainer h3{
	color: #333 !important;
	margin-bottom: 20px;
}

//App Funcionalidades
.appFuncionalidadesContainer{
	margin: 40px auto !important;
	overflow: hidden;
	width: 770px;

}

.appFuncionalidadesBoxesContainer{
	padding-top: 100px;
	float: right;
	overflow: hidden;
	width: 574px;
}

.appFuncionalidadesIphonePerspectiva{
	float: left;
	margin-right: 20px;
}

.appFuncionalidadesBox{
	background: #fff;
	border-radius: 6px;
	border: 1px solid #ddd;
	margin-bottom: 15px;
	float: right;
	padding: 15px;
	width: 570px;
}

.appFuncionalidadesBox h4{
	margin-bottom: 3px !important;
	font-size: 16px;
}

.appFuncionalidadesBoxIcon{
	float: left;
	height: 100%;
	margin-right: 15px;
	margin-top: 18px;
}

.appFuncionalidadesContent{
	float: left;
	width: 90%;
}

.appInstallContainer{
	padding-top: 60px;
}

.appInstallContainer img{
	display: block;
	margin: 0 auto;
	margin-bottom: 15px;
}

.appInstallContainer p{
	text-align: center;
	font-size: 20px;
	margin-bottom: 60px;
}

.appInstallContainer a{
	color: #005AA9 !important;
}


/* Media Queries */
/* tablets/desktops and up ----------- */
@media (min-width: 992px) and (max-width: 1199px) {

	//Banner
	.landingPageBanner{
		padding-top: 60px;
		margin-bottom: 20px;
	}


	.landingPageBanner h1{
		margin-bottom: 40px;
	}

	.landingPageBannerIphone{
		margin-top: 20px;
		margin-bottom: 10px;
	}


}

@media (min-width: 1200px){

	.landingPageBannerIphone{
		margin-top: 60px;
		margin-bottom: 10px;
	}
}

