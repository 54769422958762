/* CSS Document */
footer{
	width:100%;
}


/*Footer level 1*/
#footer-level1-wrapper{
	width: 100%;
	background: #dfdfdf;
	overflow: hidden;
}

#footer-level1{
	width: 940px;
	margin: 0 auto;
	overflow: hidden;
	padding-top: 10px;
}

#footer-social-media-icons{
	width: 320px;
	float: left;
	margin-top: 20px;
}

#footer-social-media-icons a{
	float: left;
	margin-right: 20px;
}


/*Footer level 2*/
#footer-level2-wrapper{
	width: 100%;
	background: #005aa9;
	padding: 30px 0;
}

#footer-level2{
	width: 940px;
	margin: 0 auto;
	overflow: hidden;
	line-height: 21px;
}

#footer-level2 h4{
	color: #fff;
	font-size: 14px;
	font-family: 'Proxima Nova Bold', Arial, Helvetica, sans-serif;
	margin-bottom: 5px;
}

#footer-level2-left-col{
	float: left;
	margin-right: 70px;
}

#footer-level2-left-col a{
	color: #fff;
	line-height: 25px;
	font-size: 0.9em;
}

#footer-level2-left-col a:hover{
	color: #00adee;
}

#footer-level2-right-col{
	float: left;
}


#footer-level2-right-col p{
	color: #fff;
	font-size: 12px;
}

#footer-level2 img{
	float: right;
}


/*Footer level 3*/
#footer-level3-wrapper{
	width: 100%;
	overflow: hidden;
	background: #003869;
	padding: 20px 0;
}

#footer-level3{
	width: 940px;
	margin: 0 auto;
}

#footer-level3 .left-col{
	float: left;
}

#footer-level3 h4{
	color: #fff;
	font-size: 0.8em;
	font-family: 'Proxima Nova Bold', Arial, Helvetica, sans-serif;
	margin-bottom: 2px;
}

#footer-level3 p{
	color: #fff;
	font-size: 12px;
}

#footer-level3 a{
	color: #fff;
	font-weight: bold;
	float: right;
	font-size: 12px;
}

#footer-level3 a:hover{
	color: #00adee;
}


#footer-wrapper{
	width:100%;
	margin-top:20px;
	border-top: 1px solid #000;
}


#footer-credits{
	margin:40px 0 0 0;
	height:50px;
}

#footer-credits p{
	float:left;
}
