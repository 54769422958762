.c-border-radius-5{
  border-radius: 5px;
}

.atomic-border-radius-10 {
  border-radius: 10px !important;
}

.atomic-border-radius-15 {
  border-radius: 15px !important;
}
