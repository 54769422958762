/* CSS Document */
.header-mobile {
  display: none;
}

#header-selector-mobile {
  background: #094382;
  padding: 10px;
  overflow: hidden;
}

#header-selector-btns-container-mobile {
  overflow: hidden;
  margin: 0 auto;
  width: 270px;
}

#header-main-mobile {
  background: #005aa9;
  overflow: hidden;
  padding: 10px;
  margin-bottom: 0;
}

#header-main-mobile a {
  color: #fff;
}

header {
  background: #fff;
  border-bottom: 1px solid #dfe3e8;
  font-family: "Proxima Nova Bold", Arial, Helvetica, sans-serif;
  font-size: 14px;
  min-height: 100px;
  overflow: hidden;
  width: 100%;
}

#header-wrapper {
  width: 940px;
  margin: 0 auto;
  overflow: hidden;
}

#header-system {
  width: 100%;
  overflow: hidden;
  background: #005aa9;
  color: #fff;
  padding: 10px 0;
  height: 40px;
}

#header-system-wrapper {
  overflow: hidden;
  font-size: 14px !important;
  margin: 0 auto;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 2;
}

#header-system-wrapper div {
  float: right;
  overflow: hidden;
  margin-left: 40px;
}
