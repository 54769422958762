/*CLEANERS*/
.cleaner{clear:both;}
.margin-top-cleaner{margin-top:0 !important;}
.margin-right-cleaner{margin-right:0 !important;}
.margin-bottom-cleaner{margin-bottom:0 !important;}
.margin-left-cleaner{margin-left:0 !important;}
.border-top-cleaner{border-top:0 !important;}
.border-right-cleaner{border-right:0 !important;}
.border-bottom-cleaner{border-bottom:0 !important;}
.border-left-cleaner{border-left:0 !important;}
.padding-top-cleaner{padding-top:0 !important;}
.padding-right-cleaner{padding-right:0 !important;}
.padding-bottom-cleaner{padding-bottom:0 !important;}
.padding-left-cleaner{padding-left:0 !important;}
.background-image-cleaner{background-image:none !important;}