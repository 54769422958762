@charset "utf-8";
/*PARA JESUS TODA HONRA E TODA A GLÓRIA*/

/*External Styles*/
@import "_colors.scss";
@import "_bootstrap4.3.1.min.scss";
@import "_accordion.scss";
@import "_cards.scss";
@import "normalize.scss";
@import "font-awesome.scss";
@import "_fonts.scss";
@import "cleaners.scss";
@import "floats.scss";
@import "_header.scss";
@import "_nav.scss";
@import "_buttons.scss";
@import "sidebar.scss";
@import "tooltip.scss";
@import "_forms.scss";
@import "footer.scss";
@import "_landing-pages.scss";
@import "_lead-pages.scss";
@import "_modals.scss";
@import "_margins.scss";
@import "_media-queries.scss";
@import "_text-sizes.scss";
@import "_border-radious.scss";

/*Global*/
body {
  font-family: "DM Sans", sans-serif;
  font-size: 62, 5%;
  color: #555;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F2F6F9;
}

a {
  text-decoration: none;
  display: block;
}
a:hover{
  text-decoration: none;
}

p {
  line-height: 1.6em;
  font-size: 1em;
  color: $paragraphColor;
}

.bg-satcom-map {
  background: url("../img/bg-map.png") top center no-repeat !important;
}

.subtitle {
  font-family: "proxima_nova_ltsemibold", Arial, Helvetica, sans-serif !important;
  color: #000;
  margin-bottom: 20px;
  font-size: 20px;
  margin-top: -10px !important;
  line-height: 1.2em;
}

.textoLegal {
  font-size: 0.8em;
  text-align: center;
}

.vertical-center {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

/*Logo*/
#logo-wrapper {
  width: 940px;
  margin: 0 auto;
}

#logo {
  width: 96px;
  height: 96px;
  margin-top: 13px;
  background: url("../img/logo.svg") no-repeat;
  background-size: 96px 96px;
  text-indent: -1000em;
  float: left;
  position: absolute;
  z-index: 999;
}

#logo a {
  width: 96px;
  height: 96px;
  float: left;
}

/*Zebra Stripes*/
.zebra-stripes-list {
  background: #f2f2f2;
}

/*Titles*/
.packages-titles {
  font-size: 2em;
  margin-bottom: 47px;
}

.packages-sub-titles {
  font-size: 1.2em;
  margin-bottom: 47px;
  color: #000;
  text-align: center;
}

/*Page bottom picture*/
.bottom-picture img {
  display: block;
  margin: 0 auto;
}

/*Seguradoras Logos*/
.seguradoras-logos a {
  float: left;
  border: 1px solid #c0d9f0;
  background: #ecf6fb;
  margin-right: 15.5px;
}

ul.seguradoras-logos {
  clear: both;
  overflow: hidden;
  margin: 60px auto;
  width: 705px;
}

/*Articles*/
article {
  box-sizing: border-box;
  overflow: hidden;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 40px 20px 40px 20px;
  // width: 700px;
  margin: 40px auto 40px auto;
}

article h3 {
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 40px;
}

article .article-footer {
  border-top: 1px solid #ccc;
  padding-top: 20px;
  margin-top: 40px;
}

article .article-footer h5 {
  color: #000;
  font-size: 14px;
  margin-bottom: 5px;
}

article .article-footer p {
  text-align: center;
  font-size: 14px;
  margin-bottom: 15px;
}

article .article-footer h6 {
  font-size: 18px;
  color: $lightBlue;
  font-family: "Proxima Nova Bold", Arial, Helvetica, sans-serif;
  font-weight: normal;
}

/*Banner Gestor Plus*/
#banner-gestor-plus {
  width: auto;
  height: 320px;
  background: url(../img/banner-gestor-plus.jpg) top center no-repeat;
  margin: 20px 0 60px 0;
}

/*Content*/
.content {
  width: 940px;
  margin: 0 auto;
  overflow: hidden;
}

/*Main Banner*/
#main-banner-wrapper {
  overflow: hidden;
  width: 100%;
  height: 560px;
  margin-bottom: 85px;
}

#main-banner h2 {
  color: #fff;
  text-shadow: 1px 1px #000;
  font-weight: normal;
  text-align: center;
  margin-bottom: 10px;
  margin: 0 auto;
  line-height: 1.5em;
  font-size: 2.5em;
  padding-top: 90px;
}

/*Headline*/
#headline {
  padding-bottom: 40px;
}

#headline h3 {
  font-size: 3em;
  margin-bottom: 0.3em;
}

#headline p {
  text-align: center;
  font-size: 16px;
  margin-bottom: 40px;
}

/*Pre Home*/
#home {
  background: url(../img/pre-home-hero-1.jpg) center top no-repeat;
}

.home-logo {
  width: 92px;
  height: 92px;
  margin: 20px auto;
}

#home h1 {
  font-size: 2.5em;
  text-align: center !important;
}

#home h2 {
  font-size: 45px;
  margin-bottom: 15px;
  line-height: 50px;
  font-weight: normal;
  text-align: center;
}

#home h3 {
  font-family: "proxima_nova_alt_rgregular", Arial, Helvetica, sans-serif;
  color: #000;
  font-size: 26px;
  margin-bottom: 50px;
}

#home #content {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}

/*Packages*/
.package-box-wrapper {
  width: 300px;
  box-sizing: border-box;
  float: left;
  margin-right: 20px;
  margin-bottom: 40px;
}

.package-box-wrapper p {
  text-align: center;
  font-size: 0.9em;
}

.package-boxes {
  background: #fff;
  padding-bottom: 20px;
  margin-bottom: 10px;
  min-height: 410px;
  border: 1px solid #ccc;
  border-radius: 6px;
  /*To force the button align to the bottom of this box*/
  position: relative;
}

.package-boxes h4 {
  background: $blue;
  color: #fff;
  text-align: center;
  font-size: 1.4em;
  padding: 10px 0;
  border-radius: 6px 6px 0 0;
  margin-bottom: 20px;
}

.package-price {
  width: 196px;
  margin: 0 auto;
}

.modal .package-price {
  width: 238px;
}

.modal .package-price .cifrao {
  margin-left: 30px;
}

.package-price .cifrao {
  font-family: "Proxima Nova Bold", Arial, Helvetica, sans-serif;
  font-size: 15px;
  float: left;
  margin-left: 35px;
}

.package-price .value {
  font-family: "proxima_nova_altextrabold", Arial, Helvetica, sans-serif;
  color: #000;
  font-size: 4em;
  float: left;
}

.package-price .cents {
  font-family: "proxima_nova_altextrabold", Arial, Helvetica, sans-serif;
  color: #000;
  font-size: 1.3em;
  float: left;
}

.package-boxes h6 {
  font-family: "Proxima Nova Bold", Arial, Helvetica, sans-serif;
  clear: both;
  font-size: 1em;
  font-weight: normal;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.package-boxes ul {
  margin-bottom: 20px;
}

.package-boxes li {
  line-height: 21px;
  padding: 5px 10px;
  font-size: 0.9em !important;
}

.package-boxes li:nth-child(even) {
  background: #f2f2f2;
}

/*Package 5*/
/*It has differente style because it has the same look and feel but different sizes*/
.package-box-5-wrapper {
  margin-left: 28%;
  width: 440px;
}

.package-box-5 {
  min-height: 330px;
}

.package-box-5 .cifrao {
  margin-left: 10px;
}

.package-box-5 ul {
  margin-bottom: 50px;
}

.package-5-price-from {
  color: #444;
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 10px;
}

.package-5-left-col h6,
.package-5-right-col h6 {
  color: #444;
  font-size: 14px;
  border-bottom: none;
  margin-bottom: 5px;
  margin-top: 15px;
}

.package-5-left-col {
  float: left;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 20px;
  margin-top: 10px;
}

.package-5-right-col {
  float: right;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 20px;
  margin-top: 10px;
}

.package-5-left-col ul,
.package-5-right-col ul {
  min-height: 200px;
  margin-bottom: 0;
}

.package-box-5 .btn-ask-yours {
  position: inherit;
  margin: 50px 30% -10px 30%;
  margin-bottom: -15px;
  text-align: center !important;
  width: 180px;
}

/*Features box*/
#features-box {
  width: 100%;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 20px;
}

#features-box .left-col {
  float: left;
  margin: 80px 20px 20px 20px;
  width: 420px;
  box-sizing: border-box;
}

#features-box .left-col h3 {
  font-size: 2em;
  margin-bottom: 50px;
  color: $blue;
  font-family: "Proxima Nova Bold", Arial, Helvetica, sans-serif !important;
}

#features-box .left-col img {
  margin: 0 auto;
  display: block;
}

#features-box .right-col {
  float: right;
  margin: 30px 20px 40px 20px;
  width: 380px;
}

/*Features boxes accordion*/
#features-box #accordion h3 {
  color: #000;
  font-family: "OpenSans", Arial, Helvetica, sans-serif;
  font-weight: normal;
  text-align: left;
  font-size: 16px;
  padding-left: 55px;
  background: #fff;
  margin-bottom: 0;
}

#features-box #accordion h3:hover {
  border: 1px solid $blue;
}

#features-box .ui-accordion .ui-accordion-header .ui-accordion-header-icon {
  left: 345px;
}

#features-box .ui-state-active,
.ui-widget-content,
.ui-widget-header {
  border: 1px solid $blue;
}

#features-box .ui-accordion-header-icon,
.ui-icon,
.ui-icon-triangle-1-s {
  background: url(../img/sprite.png) no-repeat 3px 3px !important;
}

#features-box .ui-state-active .ui-icon-triangle-1-s {
  background: url(../img/sprite.png) no-repeat -15px 3px !important;
}

#features-box .ui-state-focus {
  border: 1px solid $blue;
  outline: none;
}

#features-box .ui-widget-content {
  background: #fff;
  overflow: hidden;
}

#features-box .ui-accordion .ui-accordion-header {
  margin-top: 15px;
}

#features-box .ui-accordion .ui-accordion-content {
  padding: 0 10px;
}

#features-box .ui-accordion .ui-accordion-content p {
  color: #666 !important;
  padding: 10px;
  font-size: 0.8em;
}

#features-box-footer {
  clear: both;
  border-top: 1px solid #ccc;
  padding: 15px 0;
  overflow: hidden;
}

#features-box-footer p {
  float: left;
  margin-left: 20px;
  margin-top: 5px;
  color: #000;
  font-weight: bold;
  font-size: 1em;
}

#features-box-footer h5 {
  float: right;
  margin-right: 20px;
  color: $blue;
  font-weight: normal;
  font-size: 1.6em;
  font-family: "proxima_nova_altextrabold", Arial, Helvetica, sans-serif;
}

.features-legal-infos p {
  font-size: 0.8em !important;
  text-align: center;
}

/*Features boxes icons*/
#features-box .icon-shield {
  background: url(../img/icon-shield.png) no-repeat 15px 50% !important;
}
#features-box .icon-help-desk {
  background: url(../img/icon-help-desk.png) no-repeat 18px 50% !important;
}
#features-box .icon-team {
  background: url(../img/icon-team.png) no-repeat 15px 50% !important;
}
#features-box .icon-computer {
  background: url(../img/icon-computer.png) no-repeat 18px 50% !important;
}
#features-box .icon-towing {
  background: url(../img/icon-towing.png) no-repeat 15px 50% !important;
}
#features-box .icon-lock {
  background: url(../img/icon-lock.png) no-repeat 18px 50% !important;
}
#features-box .icon-thief {
  background: url(../img/icon-thief.png) no-repeat 15px 50% !important;
}
#features-box .icon-panic {
  background: url(../img/icon-panic.png) no-repeat 15px 50% !important;
}

/*Carro*/
#carro #main-banner-wrapper {
  background: url(../img/banner-car.jpg) top center no-repeat;
}

#carro .package-box-wrapper {
  margin-bottom: 60px;
}

/*Caminhao*/
#caminhao #main-banner h2 {
  padding-top: 220px;
}
#caminhao #main-banner-wrapper {
  background: url(../img/banner-truck.jpg) top center no-repeat;
}

#caminhao .packages-titles {
  margin-bottom: 5px;
}

#caminhao #features-box img {
  margin-left: 70px;
}

#caminhao .package-boxes {
  min-height: 470px;
}

/*Moto*/
#moto .moto-features-box-title {
  font-size: 30px;
  margin-top: 50px;
}

#moto #accordion {
  width: 420px;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 20px;
}

#moto #main-banner-wrapper {
  background: url(../img/banner-motorcycle.jpg) top center no-repeat;
}

#moto .package-boxes-container {
  overflow: hidden;
  margin: 0 auto;
  width: 940px;
}

#moto .package-boxes {
  min-height: 410px;
}

/*Frota*/
#frota #main-banner h2 {
  padding-top: 60px;
}

#frota #main-banner-wrapper {
  background: url(../img/banner-frota-caminhao.jpg) top center no-repeat;
}

#frota #main-banner h2 {
  min-width: 640px;
}

#frota #main-banner h3 {
  min-width: 690px;
}

#frota .package-boxes-container {
  margin: 0 auto;
  overflow: hidden;
  width: 700px;
}

#frota .package-box-wrapper {
  width: 220px;
  margin-bottom: 10px;
}

#frota .package-boxes {
  min-height: 472px;
}

#frota .package-box-5-wrapper {
  margin-left: 28%;
  width: 440px;
}

#frota .package-box-5 {
  min-height: 320px;
}

#frota .package-box-5 .btn-ask-yours {
  position: inherit;
  margin: 50px 30% -10px 30%;
  margin-bottom: -15px;
  text-align: center !important;
  width: 180px;
}

.promotion-banner {
  background: #002658;
  border-radius: 6px;
  margin-bottom: 40px;
  overflow: hidden;
  padding: 30px 0;
  width: 100%;
}

.promotion-banner h3 {
  color: #fff;
  font-size: 32px;
}

.promotion-banner h4 {
  color: #00a7ec;
  font-size: 120px;
  text-align: center;
}

.promotion-banner h5 {
  border-bottom: 1px solid #4d688a;
  color: #4d688a;
  font-size: 16px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  text-align: center;
}

.promotion-banner span {
  color: #4d688a;
  font-size: 24px;
}

.promotion-banner .btn-form-enviar {
  margin-bottom: 0 !important;
}

/*Seguro*/
#seguro #main-banner-wrapper {
  background: url(../img/banner-seguro.jpg) top center no-repeat;
}

#seguro .package-box-wrapper {
  margin-bottom: 120px;
}

#seguro #features-box {
  margin-top: 100px;
}

#seguro .package-box-wrapper,
#seguro-empresa .package-box-wrapper {
  margin-bottom: 80px;
}

#seguro .package-boxes,
#seguro-empresa .package-boxes {
  min-height: 440px;
}

#seguro-banner-reasons-wrapper {
  background: $blue;
  overflow: hidden;
  margin-top: 60px;
  width: 100%;
}

#seguro-banner-reasons {
  width: 940px;
  margin: 0 auto;
  padding-top: 40px;
}

#seguro-banner-reasons h3 {
  font-size: 35px;
  color: #fff;
  text-align: center;
  font-family: "Proxima Nova Bold", Arial, Helvetica, sans-serif !important;
  margin-bottom: 20px;
  text-align: center;
}

#seguro-banner-reasons div {
  background: $lightBlue;
  box-sizing: border-box;
  width: 220px;
  float: left;
  margin: 20px 20px 60px 0;
  padding: 20px;
  text-align: center;
  font-family: "Proxima Nova Bold", Arial, Helvetica, sans-serif !important;
}

#seguro-banner-reasons img {
  margin-bottom: 10px;
}

#seguro-banner-reasons p {
  color: #fff;
  text-align: center;
}

#seguro #features-box {
  margin-top: 40px;
}

#seguro .seguro-boxes-destaques-wrapper {
  width: 595px;
  margin: 0 auto;
  overflow: hidden;
  margin-bottom: 40px;
}

#seguro .seguro-destaque-boxes {
  background: $blue;
  width: 184px;
  float: left;
  margin: 0 20px 20px 0;
}

#seguro .seguro-destaque-boxes img {
  display: block;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 15px;
}

#seguro h3 {
  color: #fff;
  font-size: 14px;
  font-family: "proxima_nova_ltsemibold" !important;
}

#seguro .seguro-auto-topicos-box {
  border: 1px solid #ccc;
  background: #fff;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 60px;
  padding-top: 30px;
}

#seguro .seguro-auto-topicos-box h2 {
  font-size: 1.9em;
  margin-bottom: 5px;
}

#seguro .seguro-auto-topicos-box h3 {
  color: #000;
  font-size: 1.2em;
  margin-bottom: 20px;
}

#seguro .seguro-auto-lista {
  padding: 15px;
}

#seguro .seguro-auto-lista h4,
#seguro .seguro-auto-lista p {
  color: #fff !important;
  width: 90%;
  float: left;
  display: block;
  text-shadow: 0.5px 0.5px #000;
}

#seguro .seguro-auto-lista h4 {
  margin-bottom: 5px;
  margin-top: 5px;
}

#seguro .seguro-auto-lista span {
  color: #fff;
  float: right;
  border: 1px solid #fff;
  padding: 5px 14px;
  font-size: 1.8em;
  border-radius: 100%;
  display: block;
  margin-top: 4px;
}

#seguro .textoLegal {
  margin: 20px 0 80px 0;
}

#seguro .logosParceria {
  margin: 0 auto;
  display: block;
  margin-top: 30px;
  margin-bottom: 100px;
}

#seguro .seguro-auto-lista-cor1 {
  background: #07509e;
  overflow: hidden;
}
#seguro .seguro-auto-lista-cor2 {
  background: #235faa;
  overflow: hidden;
}
#seguro .seguro-auto-lista-cor3 {
  background: #3d70b2;
  overflow: hidden;
}
#seguro .seguro-auto-lista-cor4 {
  background: #577fb9;
  overflow: hidden;
}
#seguro .seguro-auto-lista-cor5 {
  background: #6f8ec1;
  overflow: hidden;
}
#seguro .seguro-auto-lista-cor6 {
  background: #859dc7;
  overflow: hidden;
}
#seguro .seguro-auto-lista-cor7 {
  background: #9fafcf;
  overflow: hidden;
}

#seguro .btn-calcular-seguro {
  margin-bottom: 60px;
}

#seguro .features-legal-infos {
  margin-bottom: 70px;
}

#seguro .bottom-picture {
  margin-top: 70px;
}

/*Seguro Empresa*/
#seguro-empresa h2 {
  padding-top: 60px;
}

#seguro-empresa .btn-calcular-seguro {
  margin-bottom: 70px;
}

#seguro-empresa .features-legal-infos {
  margin-bottom: 70px;
}

#seguro-empresa #main-banner-wrapper {
  background: url(../img/banner-frota-caminhao.jpg) top center no-repeat;
}

#seguro-empresa .package-box-wrapper {
  width: 220px;
}

#seguro-empresa .package-boxes {
  min-height: 450px;
}

/*Manuais*/
.manuais-boxes {
  box-sizing: border-box;
  border: 1px solid #ccc;
  width: 145px;
  float: left;
  margin-right: 19px;
  background: #f2f7fb;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 20px;
}

.manuais-boxes img {
  margin: 0 auto !important;
  display: block;
  padding-bottom: 15px;
}

.manuais-boxes h4,
.manuais-boxes h5 {
  color: #000;
  text-align: center;
  font-size: 20px !important;
}

.manuais-boxes h4 {
  font-family: "proxima_nova_alt_rgregular", Arial, Helvetica, sans-serif;
  margin-bottom: -15px !important;
}

.manuais-boxes h5 {
  margin-bottom: 15px;
  font-family: "Proxima Nova Bold", Arial, Helvetica, sans-serif;
}

/*Sobe Nós*/
#sobre-nos #main-banner-wrapper {
  background: url(../img/banner-sobre-nos.jpg) top center no-repeat;
}

#sobre-nos .main-banner {
  background: url(../img/banner-sobre-nos.jpg) top center no-repeat;
  padding: 190px 0 50px 0 !important;
}

#sobre-nos #main-banner h2 {
  font-size: 33px;
  width: 940px !important;
  text-align: left;
}

#sobre-nos-valores {
  overflow: hidden;
  background: #0084c4 url(../img/sobre-nos-valores-bg.jpg) no-repeat top center;
  padding: 30px;
  margin-bottom: 80px;
}

#sobre-nos-valores h4 {
  color: #fff;
  font-size: 30px;
  text-align: center;
  margin: 20px 0 40px 0;
}

#sobre-nos-valores div {
  background: #fff;
  float: left;
  margin-right: 20px;
  box-sizing: border-box;
  width: 280px;
  padding: 20px;
  border-bottom: 4px solid $lightBlue;
}

#sobre-nos-valores h5 {
  font-family: "Proxima Nova Bold", Arial, Helvetica, sans-serif;
  font-size: 24px;
  color: $blue;
  margin-bottom: 10px;
}

#sobre-nos #sobre-nos-funcionarios {
  margin-bottom: 60px;
  overflow: hidden;
}

#sobre-nos #sobre-nos-funcionarios h3 {
  font-size: 24px;
  margin-bottom: 60px;
}

#sobre-nos .funcionarios-box {
  box-sizing: border-box;
  float: left;
  width: 235px;
  margin-bottom: 60px;
}

#sobre-nos .funcionarios-box img {
  margin: 0 auto;
  display: block;
  margin-bottom: 20px;
}

#sobre-nos .funcionarios-box {
  text-align: center;
}

#sobre-nos .funcionarios-box h4 {
  font-size: 16px !important;
  margin-bottom: 5px;
}

#sobre-nos footer {
  margin-top: 80px !important;
}

/*Trabalhe com a gente*/
#trabalhe-com-a-gente #main-banner-wrapper {
  background: url(../img/banners-trabalhe-conosco.jpg) top center no-repeat;
  height: 480px;
}

#trabalhe-com-a-gente #main-banner {
  padding-top: 130px;
}

#trabalhe-com-a-gente #main-banner h2 {
  padding: 20px 160px;
  padding: 0 !important;
  margin: 0 auto;
  margin-bottom: 10px;
  font-size: 45px;
  margin-bottom: 40px;
}

#trabalhe-com-a-gente #main-banner-wrapper p {
  color: #fff;
  text-align: center;
  font-size: 20px;
  text-shadow: 1px 1px #000;
}

#trabalhe-com-a-gente section h3 {
  font-size: 30px;
  border: 1px solid #ccc;
  background: #fff;
  padding: 40px 20px 34px 40px;
  border-radius: 6px;
  margin-bottom: 160px;
  margin-top: 70px;
}

/*Promoções*/
#promocoes h3 {
  margin-bottom: 20px;
}

#promocoes span {
  display: block;
  text-align: center;
}

#promocoes article {
  box-sizing: border-box;
  float: none !important;
  overflow: hidden;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 40px 20px 40px 20px !important;
  width: 940px;
  margin: 40px 0 40px 0;
}

#promocoes .article-footer p {
  text-align: left !important;
  line-height: 2em;
}

#promocoes .article-footer h5 {
  font-size: 1.6em;
  margin-bottom: 2em;
}

/*Patio Legal*/
#patiolegal article {
  margin: 30px 0;
}

#patiolegal h1 {
  border-bottom: 1px solid #ccc;
  margin-bottom: 40px;
  padding-bottom: 30px;
}

#patiolegal h2 {
  color: #000;
  font-size: 1.2em;
  margin-bottom: 40px;
  line-height: 1.3em;
  margin-top: 40px;
}

#patiolegal h3 {
  margin-bottom: 20px;
  font-size: 1.4em;
}

#patiolegal p {
  text-align: center;
}

/*Planos*/
#planos h1 {
  line-height: 1em;
}

#planos h2 {
  line-height: 1em;
}


/*Manual Seguro Auto*/
#manual-seguro-auto #main-banner-wrapper {
  background: url(../img/banner-manual-seguro-auto.jpg) top center;
}

#manual-seguro-auto #main-banner h1,
#manual-seguro-auto #main-banner h2 {
  color: #fff;
  text-align: left;
}

#manual-seguro-auto #main-banner {
  border: 1px solid #000;
}

#manual-seguro-auto .splashIcon {
  width: 188px;
  height: 188px;
  margin: 0 auto;
  margin-bottom: 60px;
  position: relative;
  z-index: 3;
  margin-top: -190px;
}

#manual-seguro-auto .manual-seguro-auto-boxes-destaques-wrapper {
  width: 595px;
  margin: 0 auto;
  overflow: hidden;
  margin-bottom: 40px;
}

#manual-seguro-auto .manual-seguro-destaque-boxes {
  background: $blue;
  width: 184px;
  float: left;
  margin: 0 20px 20px 0;
}

#manual-seguro-auto .manual-seguro-destaque-boxes img {
  display: block;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 15px;
}

#manual-seguro-auto h3 {
  color: #fff;
  font-size: 14px;
  font-family: "proxima_nova_ltsemibold" !important;
}

#manual-seguro-auto .manual-seguro-auto-topicos-box {
  border: 1px solid #ccc;
  background: #fff;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 60px;
  padding-top: 30px;
}

#manual-seguro-auto .manual-seguro-auto-topicos-box h2 {
  font-size: 1.9em;
  margin-bottom: 5px;
}

#manual-seguro-auto .manual-seguro-auto-topicos-box h3 {
  color: #000;
  font-size: 1.2em;
  margin-bottom: 20px;
}

#manual-seguro-auto .manual-seguro-auto-lista {
  padding: 15px;
}

#manual-seguro-auto .manual-seguro-auto-lista h4,
#manual-seguro-auto .manual-seguro-auto-lista p {
  color: #fff !important;
  width: 90%;
  float: left;
  display: block;
  text-shadow: 0.5px 0.5px #000;
}

#manual-seguro-auto .manual-seguro-auto-lista h4 {
  margin-bottom: 5px;
  margin-top: 5px;
}

#manual-seguro-auto .manual-seguro-auto-lista span {
  color: #fff;
  float: right;
  border: 1px solid #fff;
  padding: 5px 14px;
  font-size: 1.8em;
  border-radius: 100%;
  display: block;
  margin-top: 4px;
}

#manual-seguro-auto .textoLegal {
  margin: 20px 0 80px 0;
}

#manual-seguro-auto .logosParceria {
  margin: 0 auto;
  display: block;
  margin-top: 30px;
  margin-bottom: 100px;
}

#manual-seguro-auto .manual-seguro-auto-lista-cor1 {
  background: #07509e;
  overflow: hidden;
}
#manual-seguro-auto .manual-seguro-auto-lista-cor2 {
  background: #235faa;
  overflow: hidden;
}
#manual-seguro-auto .manual-seguro-auto-lista-cor3 {
  background: #3d70b2;
  overflow: hidden;
}
#manual-seguro-auto .manual-seguro-auto-lista-cor4 {
  background: #577fb9;
  overflow: hidden;
}
#manual-seguro-auto .manual-seguro-auto-lista-cor5 {
  background: #6f8ec1;
  overflow: hidden;
}
#manual-seguro-auto .manual-seguro-auto-lista-cor6 {
  background: #859dc7;
  overflow: hidden;
}
#manual-seguro-auto .manual-seguro-auto-lista-cor7 {
  background: #9fafcf;
  overflow: hidden;
}

/*Importação tabela FIPE*/
#importatabelafipe .content {
  width: 800px;
  padding-top: 60px;
}

#importatabelafipe h2 {
  font-size: 1.8em;
  margin-bottom: 0;
}

#importatabelafipe h3 {
  font-weight: normal !important;
  color: #333;
  font-size: 1em;
  margin-bottom: 30px;
}

#importatabelafipe form {
  background: #fff;
  padding: 40px;
  border: 1px solid #ccc;
  width: 500px;
  margin: 0 auto;
  border-radius: 6px;
}

#importatabelafipe label {
  text-align: center;
  display: block;
  color: #000;
}

#importatabelafipe select {
  margin: 0 auto;
  display: block;
  border: 1px solid #ccc;
  width: 200px;
  padding: 10px 8px;
  margin-bottom: 30px;
  background: url(../img/contact-triangle-select.png) no-repeat 180px 50%;
}

#importatabelafipe .anexar-arquivo {
  margin: 0 auto;
  display: block;
  width: 250px;
  margin-top: 20px;
}

#importatabelafipe .btn {
  margin: 0 auto;
  background: $green;
  margin-top: 40px;
}

#importatabelafipe .btn:hover {
  background: $darkGreen;
}

/*Brand*/
#brand .content {
  margin-top: 60px;
  text-align: center;
}

#brand .content h3 {
  margin-bottom: 60px;
  color: #000;
}

#brand .content p {
  margin-bottom: 30px;
}

#brand .content img {
  margin-bottom: 10px;
}

#brand .logos-boxes {
  border-bottom: 1px solid #ccc;
  padding-bottom: 50px;
  margin-bottom: 50px;
}

#brand .logos-boxes-blue-cyan-wrapper {
  width: 462px;
  margin: 0 auto;
  overflow: hidden;
  margin-bottom: 50px;
}

#brand .logos-box-color-blue {
  padding: 15px;
  background: #005aa9;
  width: 220px;
  border-radius: 4px;
  float: left;
  margin-right: 20px;
  margin-top: 30px;
}

#brand .logos-box-color-blue h5 {
  color: #28aae1;
  font-weight: normal;
  font-size: 16px !important;
  margin-top: 20px;
}

#brand .logos-box-color-blue p {
  color: #fff;
  font-weight: bold;
  font-size: 13px !important;
  margin-bottom: 0;
}

#brand .logos-box-color-cyan {
  padding: 15px;
  background: #28aae1;
  width: 220px;
  border-radius: 4px;
  float: left;
  margin-top: 30px;
}

#brand .logos-box-color-cyan h5 {
  color: #005aa9;
  font-weight: normal;
  font-size: 16px !important;
  margin-top: 20px;
}

#brand .logos-box-color-cyan p {
  color: #fff;
  font-weight: bold;
  font-size: 13px !important;
  margin-bottom: 0;
}

#brand .logos-boxes-blue-cyan-wrapper > .logos-boxes {
  border: 0;
}

/*Brand - Satcom Serviços*/
#brand-satcom-servicos .content {
  margin-top: 60px;
  text-align: center;
}

#brand-satcom-servicos .content h3 {
  margin-bottom: 60px;
  color: #000;
}

#brand-satcom-servicos .content p {
  margin-bottom: 30px;
}

#brand-satcom-servicos .content img {
  margin-bottom: 10px;
}

#brand-satcom-servicos .logos-boxes {
  border-bottom: 1px solid #ccc;
  padding-bottom: 50px;
  margin-bottom: 50px;
}

#brand-satcom-servicos .logos-boxes-blue-cyan-wrapper {
  width: 462px;
  margin: 0 auto;
  overflow: hidden;
  margin-bottom: 50px;
}

#brand-satcom-servicos .logos-box-color-blue {
  padding: 15px;
  background: #005aa9;
  width: 220px;
  border-radius: 4px;
  float: left;
  margin-right: 20px;
  margin-top: 30px;
}

#brand-satcom-servicos .logos-box-color-blue h5 {
  color: #28aae1;
  font-weight: normal;
  font-size: 16px !important;
  margin-top: 20px;
}

#brand-satcom-servicos .logos-box-color-blue p {
  color: #fff;
  font-weight: bold;
  font-size: 13px !important;
  margin-bottom: 0;
}

#brand-satcom-servicos .logos-box-color-cyan {
  padding: 15px;
  background: #28aae1;
  width: 220px;
  border-radius: 4px;
  float: left;
  margin-top: 30px;
}

#brand-satcom-servicos .logos-box-color-cyan h5 {
  color: #005aa9;
  font-weight: normal;
  font-size: 16px !important;
  margin-top: 20px;
}

#brand-satcom-servicos .logos-box-color-cyan p {
  color: #fff;
  font-weight: bold;
  font-size: 13px !important;
  margin-bottom: 0;
}

#brand-satcom-servicos .logos-boxes-blue-cyan-wrapper > .logos-boxes {
  border: 0;
}

//Politica de privacidade
#politica-de-privacidade-cotacao-facebook h1 {
  margin-bottom: 20px;
}

#politica-de-privacidade-cotacao-facebook h2 {
  margin-bottom: 15px;
}

#politica-de-privacidade-cotacao-facebook h3 {
  color: #000;
  font-weight: normal !important;
  font-size: 14px;
  line-height: 1.5em;
  margin-bottom: 40px;
}

#politica-de-privacidade-cotacao-facebook .content {
  padding-top: 20px;
}

#politica-de-privacidade-cotacao-facebook p {
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 1.7em;
}

#politica-de-privacidade-cotacao-facebook .logosParceria {
  margin: 0 auto;
  display: block;
  margin-bottom: 40px;
}

//Comunicado de Roubo
.comunicado-roubo {
  background: #edf0f5;
}

.comunicado-roubo .main {
  margin-top: 10px;
  padding: 20px;
}

.comunicado-roubo .page-header {
  font-size: 24px !important;
  margin-bottom: 10px !important;
}

.comunicado-roubo-content-margin-top {
  margin-top: 40px;
}

.comunicado-roubo-content {
  background: #fff;
  border: 1px solid #dce5f6;
  border-radius: 6px;
  padding: 20px;
}

.comunicado-roubo-success img {
  margin-bottom: 20px;
}

.comunicado-roubo-success p {
  font-size: 18px;
  margin-bottom: 20px;
}

// Login
.login-container {
  background: #fff;
  border: 1px solid #dce5f6;
  border-radius: 6px;
  padding: 20px !important;
  margin-top: 40px !important;
}

.login-logo {
  display: block;
  margin: 0 auto;
  margin-bottom: 40px;
}

.login-title {
  font-size: 24px;
  font-weight: bold;
  color: $blue;
  text-align: center;
}

// Banner Cookie
.banner-cookie {
  background-color: rgba(0, 90, 170, 100);
  bottom: -100%;
  display: none;
  left: 0;
  right: 0;
  position: fixed;
  width: 100%;
  -webkit-box-shadow: 0px -2px 18px 3px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0px -2px 18px 3px rgba(0, 0, 0, 0.23);
  box-shadow: 0px -2px 18px 3px rgba(0, 0, 0, 0.23);
  transition: 1400ms;
}

.banner-cookie-show {
  display: block;
  bottom: 0;
}

.banner-cookie p {
  color: white;
  font-size: 0.8rem;
}

.banner-cookie a {
  color: #2bbfff;
  text-decoration: underline;
  display: inline-block;
}

.banner-cookie .btn-outline-secondary {
  border: 1px solid #2bbfff;
  color: #2bbfff;
}

.banner-cookie .btn-outline-secondary:hover {
  border: 1px solid #2bbfff;
  color: #2bbfff;
  background-color: rgba(0, 90, 170, 100);
}

// Seja parceiro
.seja-parceiro-bg-jumbotron {
  background: url("../img/seja-parceiro-banner.jpg") no-repeat center center;
  background-size: cover;
  padding: 0;
  margin-bottom: 0;
  // background-image: url("../img/seja-parceiro-banner.jpg");
}

/******************************************************************  Mobile  ****************************************************************************************/

@import "_mobile.scss";
