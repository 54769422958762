@charset "UTF-8";
/*PARA JESUS TODA HONRA E TODA A GLÓRIA*/
@font-face {
  font-family: "OpenSans";
  src: url("../fonts/OpenSans.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/OpenSans.eot?#iefix") format("embedded-opentype"),
    url("../fonts/OpenSans.woff") format("woff"),
    url("../fonts/OpenSans.ttf") format("truetype"),
    url("../fonts/OpenSans.svg#svgFontName") format("svg");
  /* Legacy iOS */
}
@font-face {
  font-family: "proxima_nova_blblack";
  src: url("../fonts/Proxima Nova Black-webfont.eot");
  src: url("../fonts/Proxima Nova Black-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Proxima Nova Black-webfont.woff2") format("woff2"),
    url("../fonts/Proxima Nova Black-webfont.woff") format("woff"),
    url("../fonts/Proxima Nova Black-webfont.ttf") format("truetype"),
    url("../fonts/Proxima Nova Black-webfont.svg#proxima_nova_blblack")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "proxima_nova_rgbold";
  src: url("../fonts/Proxima Nova Bold-webfont.eot");
  src: url("../fonts/Proxima Nova Bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Proxima Nova Bold-webfont.woff2") format("woff2"),
    url("../fonts/Proxima Nova Bold-webfont.woff") format("woff"),
    url("../fonts/Proxima Nova Bold-webfont.ttf") format("truetype"),
    url("../fonts/Proxima Nova Bold-webfont.svg#proxima_nova_rgbold")
      format("svg");
  font-weight: normal;
}
@font-face {
  font-family: "proxima_nova_ltsemibold";
  src: url("../fonts/Proxima Nova Semibold-webfont.eot");
  src: url("../fonts/Proxima Nova Semibold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Proxima Nova Semibold-webfont.woff2") format("woff2"),
    url("../fonts/Proxima Nova Semibold-webfont.woff") format("woff"),
    url("../fonts/Proxima Nova Semibold-webfont.ttf") format("truetype"),
    url("../fonts/Proxima Nova Semibold-webfont.svg#proxima_nova_ltsemibold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "proxima_nova_ththin";
  src: url("../fonts/Proxima Nova Thin-webfont.eot");
  src: url("../fonts/Proxima Nova Thin-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Proxima Nova Thin-webfont.woff2") format("woff2"),
    url("../fonts/Proxima Nova Thin-webfont.woff") format("woff"),
    url("../fonts/Proxima Nova Thin-webfont.ttf") format("truetype"),
    url("../fonts/Proxima Nova Thin-webfont.svg#proxima_nova_ththin")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "proxima_nova_altextrabold";
  src: url("../fonts/Proxima Nova Alt Extrabold-webfont.eot");
  src: url("../fonts/Proxima Nova Alt Extrabold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Proxima Nova Alt Extrabold-webfont.woff2") format("woff2"),
    url("../fonts/Proxima Nova Alt Extrabold-webfont.woff") format("woff"),
    url("../fonts/Proxima Nova Alt Extrabold-webfont.ttf") format("truetype"),
    url("../fonts/Proxima Nova Alt Extrabold-webfont.svg#proxima_nova_altextrabold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "proxima_nova_alt_rgregular";
  src: url("../fonts/Proxima Nova Alt Regular-webfont.eot");
  src: url("../fonts/Proxima Nova Alt Regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Proxima Nova Alt Regular-webfont.woff2") format("woff2"),
    url("../fonts/Proxima Nova Alt Regular-webfont.woff") format("woff"),
    url("../fonts/Proxima Nova Alt Regular-webfont.ttf") format("truetype"),
    url("../fonts/Proxima Nova Alt Regular-webfont.svg#proxima_nova_alt_rgregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova Semibold";
  src: url("../fonts/Proxima Nova Semibold-webfont.eot");
  src: url("../fonts/Proxima Nova Semibold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Proxima Nova Semibold-webfont.woff2") format("woff2"),
    url("../fonts/Proxima Nova Semibold-webfont.woff") format("woff"),
    url("../fonts/Proxima Nova Semibold-webfont.ttf") format("truetype"),
    url("../fonts/Proxima Nova Semibold-webfont.svg#proxima_nova_alt_rgregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova Bold";
  src: url("../fonts/Proxima Nova Bold-webfont.eot");
  src: url("../fonts/Proxima Nova Bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/Proxima Nova Bold-webfont.woff2") format("woff2"),
    url("../fonts/Proxima Nova Bold-webfont.woff") format("woff"),
    url("../fonts/Proxima Nova Bold-webfont.ttf") format("truetype"),
    url("../fonts/Proxima Nova Bold-webfont.svg#proxima_nova_alt_rgregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

// Google Fonts
@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Poppins:wght@600&display=swap");

.atomic-font-poppins {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.atomic-font-dm-sans {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
}

// Titles
h2 {
  font-family: "proxima_nova_altextrabold", Arial, Helvetica, sans-serif;
  color: $blue;
  font-size: 35px;
  margin-bottom: 10px;
  line-height: 47px;
  text-align: center;
}

h3 {
  color: $blue;
  font-family: "Proxima Nova Bold", Arial, Helvetica, sans-serif !important;
  font-weight: normal !important;
  margin-bottom: 10px;
  text-align: center;
}

h4 {
  font-family: "proxima_nova_alt_rgregular", Arial, Helvetica, sans-serif;
  color: $blue;
  margin-bottom: 10px;
}

.proximaNovaThin {
  font-family: "proxima_nova_ththin", Arial, Helvetica, sans-serif !important;
}

.proxima_nova_altextrabold {
  font-family: "proxima_nova_altextrabold", Arial, Helvetica, sans-serif !important;
}

.proxima-nova-bold {
  font-family: "Proxima Nova Bold", Arial, Helvetica, sans-serif !important;
  font-weight: normal !important;
}

// Poppins
.c-font-poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.c-font-poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.c-font-poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.c-font-poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

// Font size
.w-f-size-12 {
  font-size: 12px !important;
}

.w-f-size-13 {
  font-size: 13px !important;
}

.w-f-size-14 {
  font-size: 0.9em !important;
}

.w-f-size-16 {
  font-size: 1em !important;
}

.w-f-size-18 {
  font-size: 18px !important;
}
.w-f-size-20 {
  font-size: 20px !important;
}
.w-f-size-25 {
  font-size: 25px !important;
}
.w-f-size-30 {
  font-size: 30px !important;
}
.w-f-size-35 {
  font-size: 35px !important;
}
.w-f-size-40 {
  font-size: 40px !important;
}
.w-f-size-50 {
  font-size: 50px !important;
}

.w-f-size-60 {
  font-size: 60px !important;
}

.w-f-size-90 {
  font-size: 90px !important;
}
