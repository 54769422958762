.atomic-text-size-13 {
  font-size: 0.8em !important;
}

.atomic-text-size-14 {
  font-size: 0.9em !important;
}

.atomic-text-size-16 {
  font-size: 1em !important;
}

.atomic-text-size-18 {
  font-size: 1.1em !important;
}

.atomic-text-size-20 {
  font-size: 1.22em !important;
}

.atomic-text-size-24 {
  font-size: 1.5em !important;
}

.atomic-text-size-30 {
  font-size: 1.9em !important;
}

.atomic-text-size-40 {
  font-size: 2.5em !important;
}

.atomic-text-size-50 {
  font-size: 3.1em !important;
}
