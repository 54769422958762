@charset "utf-8";
/*PARA JESUS TODA HONRA E TODA A GLÓRIA*/

// Bootstrap overlapping default values
.btn-primary{
	background: $blue;
	font-weight: bold;
}
.btn-outline-primary{
	  color: $blue;
    border-color: $blue;
}


.btn-form-enviar{
	background:$green;
	color: #fff;
	display: block;
	font-family: 'Proxima Nova Bold', Arial, Helvetica, sans-serif;
	font-size: 14px;
	margin: 0 auto !important;
	padding: 25px 45px;
}

/*Pre Home*/
.btn-pre-home{
	font-family: 'Proxima Nova Semibold', Arial, Helvetica, sans-serif;
	background: $lightBlue;
	color: #fff;
	float: left;
	margin-right: 10px;
	font-size: 14px !important;
	padding: 15px 45px;
	border-radius: 4px;
}

.btn-pre-home:hover{
	background: $lightBlue;
}

.btn-pre-home-wrapper{
	width: 362px;
	margin: 0 auto;
	overflow: hidden;
}

.btn-para-voce-header-selector, .btn-para-empresa-header-selector{
	border-radius: 4px;
	padding: 10px 12px;
	float: left;
	font-size: 0.9em;
	color: #fff;
	background: $blue;
}

.btn-para-empresa-header-selector{
	margin-right: 10px;
}

.btn-para-voce-header-selector{
	margin-right: 10px;
}

.icon-watsapp {
	float: left;
}


/*We Call to You*/
.btn-call{
	border: 1px solid #ccc;
	width: 260px;
	box-sizing: border-box;
	background: #fff url(../img/girl-support.png) no-repeat 0 7px;
	margin: 0 auto;
	border-radius: 6px;
	padding: 10px 16px;
	overflow: hidden;
}

.btn-call:hover{
	border: 1px solid #999;
}

.btn-call div{
	width: 140px;
	float: right;
}

.btn-call h4, h5, h6{
	text-align: center;
}

.btn-call h4{
	color: #000;
	font-size: 16px;
	margin-bottom: 2px;
}

.btn-call h5{
	color: #000;
	font-size: 12px;
	margin-bottom: 5px;
}

.btn-call h6{
	color: $blue;
	font-size: 16px;
	font-weight: bold;
}


/*Calcular Seguro*/
.btn-calcular-seguro:hover, .btn-pesquisa:hover{
	background: $darkGreen;
}


#seguro-calculadora-response a.btn-call{
	margin: 40px auto 0 300px !important;
}


/* Form calculadora do seguro */
.btn-calcular-seguro-seletores-container
{
	overflow: hidden;
	margin-bottom:25px;
	padding: 15px;
	width: 50%;
	margin: 0 auto;
}

.btn-seletor-calcularora-seguro input[type=radio]+label
{
	border-radius: 4px;
	display: inline-block !important;
	color: #c7d5e3;
	border: 1px solid #c7d5e3;
	font-weight: bold;
	font-size: 14px;
	margin-bottom: 20px;
	cursor: pointer;
	width: 100%;
	padding:15px 0;
	text-align: center;
}


.calculadora-seguro-btn-active input[type=radio]+label
{
	background: $lightBlue !important;
	color: #fff;
}


/*Hide the radio buttons*/
#calculadora-seguro-btn-carro input[type=radio],
#calculadora-seguro-btn-moto input[type=radio],
#calculadora-seguro-btn-caminhao input[type=radio]
{
	display: none;
}



/*Carro*/
#carro .btn-calcular-seguro{
	margin: 110px auto !important;
}



/*Ask Yours*/
a.btn-ask-yours{
	font-family: 'Proxima Nova Bold', Arial, Helvetica, sans-serif;
	background: $lightBlue !important;
	border-radius: 4px;
	padding-top:20px;
	padding-bottom:20px;
	color: #fff;
	// margin-left: 22px;
	font-size: 0.8em;

	/*To force the button aling to the bottom of the contatiner .The container MUST be set to posiong relative.
	In this case the class .package-boxes*/
	position: absolute;
	bottom: 20px;
	text-align: center;
	width: 100%;
}

a:hover.btn-ask-yours{
	background: $darkBlue !important;
	color: #fff;
}


/*Solicite o Seu*/
.btn-solicite-o-seu{
	font-family: 'Proxima Nova Bold', Arial, Helvetica, sans-serif;
	background: $lightBlue !important;
	border-radius: 4px;
	padding-top:20px;
	padding-bottom:20px;
	color: #fff;
	margin-left: 20px !important;
	font-size: 0.8em;

	/*To force the button aling to the bottom of the contatiner .The container MUST be set to posiong relative.
	In this case the class .package-boxes*/
	position: absolute;
	bottom: 20px;
	text-align:center;
	width: 260px;
	margin: 0 auto;
}


.btn-solicite-o-seu:hover{
	background: $darkBlue !important;
	color: #fff;
	text-decoration: none;
}

.btn-package-padding{
	padding:20px;
	border: 1px solid #000 !important;
	overflow: hidden;
}

#para-empresa .btn-solicite-o-seu{
	width: 180px;
}


/*Package lightbox */
.btnModalSolicitar{
	font-family: 'Proxima Nova Bold', Arial, Helvetica, sans-serif;
	background: $green !important;
	border-radius: 4px;
	padding: 15px 40px;
	color: #fff;
	margin: 0 auto;
	width: 100%;
}

.btnModalSolicitar:hover{
	background: $darkGreen !important;
}


/*Talk to us*/
.btn-talk-to-us{
	margin:60px auto;
	background: $green;
	color: #fff;
	padding: 15px 40px;
	font-size: 18px;
	border-radius: 6px;
}

.btn-talk-to-us:hover{
	background: $darkGreen;
}


/*Manuais*/
.btn-manuais-download{
	background: $green;
	color: #fff;
	padding: 11px 17px;
	font-size: 12px;
	border-radius: 6px;
}

.btn-manuais-download:hover{
	background: $darkGreen !important;
}


/*Rastrear veiculo*/
.btn-rastrear-veiculo{
	background: $green;
	color: #fff;
	padding: 16px 27px;
	font-size: 14px;
	border-radius: 6px;
	margin: 0 auto;
}

.btn-rastrear-veiculo:hover{
	background: $darkGreen !important;
}


/*Pesquisa*/
.btn-pesquisa{
	margin: 0 auto;
	background:$green;
	margin-top: 20px;
}



// Login
.btn-login{
	background:$blue;
	color: #fff;
	font-size: 20px;
	font-weight: bold;
	padding: 18px 38px;
	width: 100%;
}


/*Contact*/
.btn-contato{
	background: $green;
	margin: 0 auto !important;
	display: block;
}

.btn-contato:hover{
	background: $darkGreen;
}


/*Logos*/
#brand .logos-download-2-btn-warpper{
	margin: 0 auto;
	overflow: hidden;
}

#brand .logos-btn-download{
	border: 1px solid #ccc;
	border-radius: 4px;
	background: #fff;
	padding: 20px 40px;
	font-size: 13px;
	color: #28aae1;
	margin-right: 20px;
	width: 250px;
	margin: 0 auto;
	margin-bottom: 20px;
}

#brand .logos-btn-download>span{
	color: #000;
	font-weight: bold;
}

// Btn Whatsapp floating - 1
.w-btn-whatsapp-floating {
	background-color: #25d366;
	border-radius: 50px;
	bottom: 110px;
	box-shadow: 0px 0px 50px rgba(82, 86, 96, 0.9);
	color: #fff;
	font-size: 14px;
	position: fixed;
	right: 40px;
	text-decoration: none !important;
	z-index: 100;
}

// Btn Whatsapp floating - 2
.w-btn-whatsapp-floating-2 {
	background-color: #25d366;
	border-radius: 50px;
	bottom: 180px;
	box-shadow: 0px 0px 50px rgba(82, 86, 96, 0.9);
	color: #fff;
	font-size: 14px;
	position: fixed;
	right: 40px;
	text-decoration: none !important;
	z-index: 200;
}

// Btn Whatsapp floating - 3
.w-btn-whatsapp-floating-3 {
	background-color: #25d366;
	border-radius: 50px;
	bottom: 40px;
	box-shadow: 0px 0px 50px rgba(82, 86, 96, 0.9);
	color: #fff;
	font-size: 14px;
	position: fixed;
	right: 40px;
	text-decoration: none !important;
	z-index: 300;
}


/*Footer help*/
.footer-help-button{
	float: right;
	overflow: hidden;
	width: 260px;
}

.footer-help-button img, .footer-help-button h4, .footer-help-button h5{
	float: left;
}

.footer-help-button h4{
	font-size: 16px;
	margin: 20px 5px 5px 10px;
	color: #000;
}

.footer-help-button h5{
	font-size: 14px;
	color: $blue;
	margin-left: 10px;
}