.card-blue {
  background-color: $blue;
}

.brand-card-list-title {
  color: #ccdeee;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
}

.brand-card-list-paragraph {
  color: #80add4;
}

.brand-card-list-li {
  border-bottom: 1px solid #1d62ab;
  padding: 10px;
}

// Plans Page
.atomic-card-plan-1{
  border-top: 10px solid #28AAE1 !important;
}
.atomic-card-plan-1 a{
  background-color: #28AAE1 !important;
  border: 1px solid #28AAE1 !important;
}

.atomic-card-plan-1:hover {
  border: 2px solid #28AAE1;
  cursor: pointer;
}

.atomic-card-plan-2 {
  border-top: 10px solid #004B99 !important;
}

.atomic-card-plan-2 a {
  background-color: #004B99 !important;
  border: 1px solid #004B99 !important;
}

.atomic-card-plan-2:hover {
  border: 2px solid #004b99;
  cursor: pointer;
}

.atomic-card-plan-3 {
  border-top: 10px solid #27A745 !important;
}

.atomic-card-plan-3 a {
  background-color: #27A745 !important;
  border: 1px solid #27A745 !important;
}

.atomic-card-plan-3:hover {
  border: 2px solid #27A745;
  cursor: pointer;
}

.atomic-card-plan {
  border: none;
  border-radius: 20px;
  text-decoration: none !important;
  padding-bottom: 12px;
}

.atomic-card-plan a {
  text-decoration: none !important;
}

.atomic-card-plan-currency-symbol {
  color: #8e96af;
  vertical-align: super;
}

.atomic-card-plan-list {
  color: #000;
  line-height: 1.6em;
}

.atomic-card-plan-alert {
  background-color: rgba(0, 90, 169, 0.05);
  padding: 10px;
  border-radius: 5px;
}

.atomic-card-plan-alert p {
  color: #555 !important;
  line-height: 1.4em;
}

.atomic-card-plan-footer {
  padding: 0 15px 15px 15px;
  background-color: none;
  border: none;
}
