.modal-header {
  background: #fff;
  border-radius: 10px 10px 0 0;
}

.modal-title {
  color: #000;
  font-size: 20px;
}

.modal-content{
  border-radius: 10px;
}

.close {
  float: right;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1;
  color: #718093;
}

.modal h6 {
  font-weight: normal;
  color: #718093;
}

.modal-footer {
  justify-content: center;
}

.modalClose {
  width: 25px !important;
  height: 25px !important;
  background: url(../img/btn-lightbox-close.png) no-repeat 0 0;
  float: right;
}


.modalPriceBox {
  background: #e5eef5;
  padding: 20px;
  width: 230px;
  box-sizing: border-box;
  margin: 0 auto;
  color: #000 !important;
  border-radius: 4px;
  margin-bottom: 20px;
}


.modalCifrao {
  font-family: 'Proxima Nova Bold', Arial, Helvetica, sans-serif;
  font-size: 15px;
  float: left;
  margin-left: 35px;
}

.modalValue {
  font-family: 'proxima_nova_altextrabold', Arial, Helvetica, sans-serif;
  color: #000;
  font-size: 4em;
  float: left;
}

.modalCents {
  font-family: 'proxima_nova_altextrabold', Arial, Helvetica, sans-serif;
  color: #000;
  font-size: 1.3em;
  float: left;
}

.modalCents p {
  text-align: center;
  font-size: 0.9em;
}



