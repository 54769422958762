@charset "utf-8";
/*Louvado seja Deus acima de todas as coisas*/

/* Remove the spinner in number fields*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

/*Removing textarea resizing*/
textarea {
  resize: none;
}

option {
  cursor: pointer !important;
}

fieldset {
  border: none;
  padding: 0;
  margin-bottom: 30px;
}

.custom-input-label {
  color: #6E7893;
}

.color-form-label{
  color: #6E7893;
}

.form-legend {
  color: $blue !important;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 21px;
  text-align: center;
  font-size: 16px;
}

// Bootstrap invalid feedback
.invalid-feedback {
  background: #feeaed;
  color: #dd3348;
  padding: 5px 8px;
  border-radius: 3px;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none;
}

// Error messages
.input-text-error {
  color: #a94442;
}

.form-select {
  background: url(../img/dropDownMenuArrow.png) no-repeat 97% 50%;
  padding-left: 10px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  // color: #999;
}

// Option inside selec tag
.form-option {
  color: #999;
}

// Inputs - Bootstrap
.form-control {
  background: #f8f8fc;
  border: 1px solid #d6e0ef;
  color: #000 !important;
}

.input-color {
  color: #000 !important;
}

// Login
.login-forget-credentials {
  text-decoration: underline;
  color: #999;
}

/*jQuery Form validation*/
/*Classe para validação dos campos dos formularios.*/
div.form_error {
  width: 100%;
  border: 1px solid #de9397;
  clear: both;
  background: #ffecee;
  padding: 10px 16px;
  color: #9c1c1f;
  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
}

// Calculadora Seguro
#calculadora-seguro div.form_error {
  width: 320px !important;
}

#contact-form {
  font-size: 14px;
  margin: 0 auto;
  width: 562px;
}

#contact-form input,
#contact-form textarea {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 8px 8px 34px;
  margin-bottom: 20px;
}

#contact-form input {
  width: 270px;
  float: left;
  margin-right: 20px;
  color: #000;
}

#contact-form .contact-field-nome {
  background: url(../img/icon-package-lightbox-form-field-user.png) no-repeat
    10px 50%;
}

#contact-form .contact-field-mail {
  background: url(../img/icon-package-lightbox-form-field-mail.png) no-repeat
    10px 50%;
}

#contact-form .contact-field-phone {
  background: url(../img/icon-package-lightbox-form-field-phone.png) no-repeat
    10px 50%;
}

#contact-form select {
  margin-bottom: 40px;
  float: left;
  padding: 8px;
  width: 268px;
  border: 1px solid #ccc;
  background: url(../img/contact-triangle-select.png) no-repeat 96% 50%;
}

#contact-form textarea {
  height: 190px;
  padding-left: 8px;
  width: 558px;
  color: #000;
  margin-top: -15px;
}

/*Ouvidoria Form*/
#ouvidoria h1 {
  margin-bottom: 10px;
}

#ouvidoria article p {
  text-align: center;
  margin-bottom: 40px;
}

#ouvidoria-form {
  font-size: 14px;
  margin: 0 auto;
  width: 420px;
}

#ouvidoria-form input,
#ouvidoria-form textarea {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 12px 12px 12px 34px;
  margin-bottom: 20px;
}

#ouvidoria-form input {
  width: 270px;
  margin-right: 20px;
  color: #000;
  width: 400px;
}

#ouvidoria-form .contact-field-protocolo {
  background: url(../img/icon-package-lightbox-form-field-protocolo.png)
    no-repeat 10px 50%;
}

#ouvidoria-form .contact-field-nome {
  background: url(../img/icon-package-lightbox-form-field-user.png) no-repeat
    10px 50%;
}

#ouvidoria-form .contact-field-mail {
  background: url(../img/icon-package-lightbox-form-field-mail.png) no-repeat
    10px 50%;
}

#ouvidoria-form .contact-field-phone {
  background: url(../img/icon-package-lightbox-form-field-phone.png) no-repeat
    10px 50%;
  float: left;
}

#ouvidoria-form textarea {
  height: 190px;
  padding-left: 8px;
  width: 400px;
  color: #000;
}

#ouvidoria-form .form_error {
  width: 100%;
}

/*Email Response*/
#email-response h3 {
  margin-bottom: 10px;
}

#email-response p {
  font-size: 16px;
  text-align: center;
  color: #000;
}

#email-response article {
  margin-top: 50px;
  margin-bottom: 200px;
}

.mail-response h3 {
  margin-bottom: 15px;
}

.mail-response p {
  text-align: center;
}

/*Email Response*/
#email-response h3 {
  margin-bottom: 10px;
}

#email-response p {
  font-size: 16px;
  text-align: center;
  color: #000;
}

#email-response article {
  margin-top: 50px;
  margin-bottom: 200px;
}

.mail-response h3 {
  margin-bottom: 15px;
}

.mail-response p {
  text-align: center;
}

/*Packages Lightboxes*/
.package-lightbox-form div.form_error {
  width: 350px;
}

/*Mail Response Packages*/
#mail-response-packages article {
  float: none;
  margin: 100px auto;
}

#mail-response-packages article h3 {
  font-size: 28px;
  margin-bottom: 10px;
}

#mail-response-packages article p {
  text-align: center;
  font-size: 20px;
}

/*Calculadora de Seguro*/
#calculadora-seguro article,
#calculadora-seguro-temp article {
  padding: 0;
}

.calculadora-seguro-headline {
  background: #005aa9;
  border-bottom: 1px solid #ccc;
  padding: 20px;
}

.calculadora-seguro-headline h1 {
  color: #fff !important;
}

.calculadora-seguro-headline p {
  color: #fff !important;
}

// Comunicado de Roubo
.comunicado-roubo th {
  color: #528cc2;
  border-bottom: 1px solid #e7ebf3 !important;
}

.comunicado-roubo td > a {
  color: #4e5665;
}

.comunicado-roubo-table-number {
  color: #ccc;
}

.comunicado-roubo label {
  font-weight: normal;
  color: #777;
}

.table-striped > tbody > tr:nth-of-type(even) {
  background: rgba(8, 92, 170, 0.05) !important;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background: #fff !important;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 0;
}

/*Boleto Bancario*/
#boleto-bancario h3 {
  margin-bottom: 10px;
}

#boleto-bancario p {
  text-align: center;
}

/*Pesquisa*/
#pesquisa form {
  border: 1px solid #ccc;
  background: #fff;
  padding: 40px 60px;
  margin: 0 auto;
  width: 650px;
  border-radius: 6px;
  margin-bottom: 40px;
  margin-top: 40px;
  text-align: center;
}

#pesquisa form h2 {
  margin-bottom: 30px;
}

#pesquisa label {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  margin-top: 20px;
  color: #000;
  font-weight: bold;
  line-height: 1.4em;
}

#pesquisa input[type="text"] {
  padding: 6px;
  color: #555 !important;
  width: 100%;
  margin-bottom: 20px;
  width: 300px;
}

#pesquisa input[type="radio"] {
  margin: 0 10px 15px 15px;
}

#pesquisa input[type="email"] {
  padding: 6px;
  width: 300px;
  margin-bottom: 20px;
}

// Checklist Viagem
#checklist-viagem input[type="checkbox"] {
  margin-right: 10px;
  margin-bottom: 15px;
}

#checklist-viagem form > p {
  border-top: 1px solid #ccc;
  text-align: center;
  margin-top: 40px;
  padding-top: 20px;
  color: $blue;
}

// Tabela Fipe importação
.tabela-fipe-import-success {
  padding: 20px 15px;
  border: 1px solid #cee1c0;
  background: #dff0d8;
  color: #3c763d;
  overflow: hidden;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
  width: 500px;
  margin: 0 auto;
  margin-bottom: 25px;
}

/*Botstrap custom form fields */
select.input-lg {
  line-height: 23px;
  background: url(../img/contact-triangle-select.png) no-repeat 96% 50%;
}


// Boostrap attachment btn
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.85rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Anexar";
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
  font-size: .9em;
}


// Attachment Button
input[type="file"].w-file-input-button{
  display: none;
}

.w-file-input-label{
  background: #E9ECEF;
  color: #495057 !important;
  text-align: center;
  border:1px solid #CED4DA;
  border-radius: 4px;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
}