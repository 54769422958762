/* CSS Document */

.sidebar-area-cliente{
	float: left;
	box-sizing: border-box;
	font-size: 14px;
	margin:40px 20px 0 0;
}

.sidebar-area-cliente a{
	color: #005AA9;
	line-height: 26px;
}

.sidebar-area-cliente a:hover, .sidebar-area-cliente-ative {
	color: #000;
	font-weight: bold;
}
