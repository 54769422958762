// Frotas
#frota .accordion .card-header:after {
  font-family: "FontAwesome";
  content: "\f068";
  color: #005aa9;
  float: right;
}

#frota .accordion .card-header.collapsed:after {
  /* symbol for "collapsed" panels */
  content: "\f067";
  color: #005aa9;
  margin: 0 !important;
}

#frota .card-title {
  margin: 0;
  color: #000;
  font-weight: bold;
  font-size: 1.1rem;
  float: left;
  width: 90%;
}

// Planos
#planos .accordion .card-header:after {
  font-family: "FontAwesome";
  content: "\f068";
  color: #fff !important;
  float: right;
}

#planos .accordion .card-header.collapsed:after {
  /* symbol for "collapsed" panels */
  content: "\f067";
  color: #fff !important;
  margin: 0 !important;
}

#planos .accordion .card-title {
  margin: 0;
  float: left;
}
