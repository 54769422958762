.bg-satcom-lead-rastreador-carro{ background: url('../img/bg-lead-rastreador-carro.jpg') no-repeat top center; }
.bg-satcom-lead-rastreador-moto{ background: url('../img/bg-lead-rastreador-moto.jpg') no-repeat top center; }
.bg-satcom-lead-rastreador-caminhao{ background: url('../img/bg-lead-rastreador-caminhao.jpg') no-repeat top center; }

.wortek-hero-full-viewport{
  position:fixed !important;
  position:absolute !important;
  top:0 !important;
  right:0 !important;
  bottom:0 !important;
  left:0 !important;
}