/* CSS Document*/

/*Nav*/
.nav-mobile{
	background: #fff;
	margin: 0;
	padding: 15px 0;
	display: none;
}

.nav-mobile a{
	line-height: 1.8em;
	color: #003973;
	padding: 0 10px;
	font-size: 1.1em;
	text-decoration: none;;
}


.mobile-nav-divider{
	border-bottom: 1px solid #eee;
	padding-bottom: 12px;
	margin-bottom: 12px;
}

// Hamburger btn
.btn-menu-mobile{
	width: 25px;
	font-size: 34px;
	float: right !important;
	margin-top: 20px;
}

.btn-menu-mobile:hover>.nav-mobile{
	display: block !important;
}

.logo-mobile{
	width:100px;
	float: left;
}



/*Desktop*/
nav {
	margin: 15px 0 15px 0;
	overflow: hidden;
}


/*Main Menu */
.nav-link{
	font-size: 16px;
}

.nav-link{
	padding: .5rem .8rem;
}

nav #menu{
	float: right;
	margin-left: 150px;
	padding-top: 5px;
	position: absolute;
	overflow: hidden;
	z-index: 999;
}

nav #menu li{
	float:left;
	list-style-type: none;
	margin-right:60px;
	overflow: hidden;
}

nav #menu a{
	color: #000;
}

nav #menu a:hover, .nav-active{
	color:#005aa9;
}



/*Dropdown Desktop*/
#dropdown-submenu{
	background: #fff;
	box-sizing: border-box;
	display: none;
	margin-top: 19px;
	overflow: hidden;
	padding: 10px;
}

#dropdown-submenu li{
	background: #005AAD;
	float: left;
	overflow: hidden;
	margin-right: 0 !important;
}

#dropdown-submenu img{
	margin-bottom: 5px;
	opacity: 0.5;
}

#dropdown-submenu a{
	box-sizing: border-box;
	color: #fff !important;
	font-size: 16px !important;
	padding: 15px;
	text-align: center;
	width:120px;
}

#dropdown-submenu a:hover{
	background: #00AFF2;
}

#dropdowm-menu:hover>#dropdown-submenu{
	display: block;
}



// Area do cliente
#area-cliente-menu a{
	color: #fff;
}

#area-cliente-menu:hover>#area-cliente-submenu{
	display: block;
}


// Area do cliente Tooltip
#area-cliente-submenu {
	display: none;
	position: relative;
	background: #fff;
	border: 4px solid #fff;
	margin-top: 10px;
	border-radius: 4px;
}

#area-cliente-submenu:after, #area-cliente-submenu:before {
	bottom: 100%;
	left: 10%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

#area-cliente-submenu:after {
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #fff;
	border-width: 5px;
	margin-left: -5px;
}

#area-cliente-submenu:before {
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #fff;
	border-width: 11px;
	margin-left: -11px;
}


#area-cliente-submenu a{
	color: #444 !important;
	padding: 10px;
	border-bottom: 1px solid #eeeeee;
}

#area-cliente-submenu a:hover{
	background: #f1f1f1;
}


// Comunicado de Roubo
.nav-comunicado-roubo{
	background: $blue;
	border: none !important;
	margin-top: 0;
}

.nav-comunicado-roubo a{
	color: #fff !important;
}


.nav-comunicado-roubo .navbar-toggle{
	border: 1px solid $blue;
}

.nav-comunicado-roubo .navbar-toggle:hover{
	background: $blue;
}


